import React from "react";
import "./custom-button.css";

const CustomButton = ({
    displayText,
    extraClass,
    fw,
    formbutton,
    bungedFormButton,
    ...otherProps
}) => {
    return (
        <button
            className={`custom-button ${extraClass ? `${extraClass}` : ""} ${
                fw ? "fw" : ""
            } ${formbutton ? "form-button" : ""} ${
                bungedFormButton ? "bunged-form-button" : ""
            }`}
            {...otherProps}
        >
            {displayText}
        </button>
    );
};

export default CustomButton;
