import React from "react";
import "./custom-select.css";

const CustomSelect = ({
    data,
    selectText,
    name,
    bungedForm,
    ...otherProps
}) => {
    return (
        <select
            name={name}
            {...otherProps}
            className={`formfield ${bungedForm ? "bungedForm" : ""}`}
        >
            <option value>{selectText}</option>
            {data.map((option, i) => {
                return (
                    <option key={i} value={option.value}>
                        {option.text}
                    </option>
                );
            })}
        </select>
    );
};

export default CustomSelect;
