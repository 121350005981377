import React, { useState } from "react";
import "../signin/signin.css";
import FeaturedBox from "../../components/featured-box/featured-box";
import { Alert, Col, Row, Spinner } from "react-bootstrap";
import CustomButton from "../../components/custom-button/custom-button";
import MyContainer from "../../components/mycontainer/mycontainer";
import CustomInput from "../../components/custom-input/custom-input";
import Magic from "../../assets/images/signin-magic.jpg";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../components/document-title/document-title";
import apiConnect from "../../api/apiConnect";
import { connect } from "react-redux";
import {
    setUser,
    setUserBookmarks,
    setUserPurchases,
} from "../../redux/user/user.actions";

const ForgotPassword = () => {
    useDocumentTitle("Forgot Password");
    const [signInLoading, setSignInLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    const doForgot = async (e) => {
        e.preventDefault();
        setError("");
        setMessage("");
        setSignInLoading(true);
        try {
            const bodyForm = new FormData();
            bodyForm.append("email", email);
            const response = await apiConnect.post("/forgotPassword", bodyForm);
            if (response.data.status === "success") {
                setMessage(
                    "Instructions to reset your password has been sent to your inbox"
                );
                setSignInLoading(false);
            } else {
                setError(response.data.message);
                setSignInLoading(false);
            }
        } catch (error) {
            console.log(error.response);
            setSignInLoading(false);
            setError(error.response?.data.message || "Something went wrong");
        }
    };

    return (
        <div className="signin">
            <div className="signup-banner">
                <MyContainer>
                    <h3 className="title">
                        Enter into a world of creative magic
                    </h3>
                </MyContainer>
            </div>
            <FeaturedBox extraClass="signup-div">
                <MyContainer>
                    <Row>
                        <Col md={7}>
                            <img
                                className="img-fluid w-100"
                                alt="Welcome to a world of magic"
                                src={Magic}
                            />
                        </Col>
                        <Col md={5}>
                            <form onSubmit={doForgot}>
                                <div className="signup-form">
                                    <h3 className="signup-title">
                                        Forgot Password
                                    </h3>
                                    <div>
                                        {error && (
                                            <Alert variant="danger">
                                                {error}
                                            </Alert>
                                        )}
                                        {message && (
                                            <Alert variant="success">
                                                {message}
                                            </Alert>
                                        )}
                                    </div>
                                    <Row>
                                        <Col md={12}>
                                            <div className="form-group">
                                                <CustomInput
                                                    name="email"
                                                    value={email}
                                                    onChange={(e) => {
                                                        const { value } =
                                                            e.target;
                                                        setEmail(value);
                                                    }}
                                                    required
                                                    placeholder="Email"
                                                />
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className="form-group">
                                                Don't have an account?{" "}
                                                <Link to="/signup">
                                                    Sign Up
                                                </Link>
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className="form-group">
                                                <CustomButton
                                                    displayText={
                                                        signInLoading ? (
                                                            <Spinner
                                                                animation="border"
                                                                size="sm"
                                                            />
                                                        ) : (
                                                            "Continue"
                                                        )
                                                    }
                                                    formbutton
                                                    fw
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </MyContainer>
            </FeaturedBox>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    setUser: (user) => dispatch(setUser(user)),
    setBookmarks: (bookmarks) => dispatch(setUserBookmarks(bookmarks)),
    setPurchases: (purchases) => dispatch(setUserPurchases(purchases)),
});

export default connect(null, mapDispatchToProps)(ForgotPassword);
