import React from "react";
import "./featured-box.css";
import { Container, Row, Col } from "react-bootstrap";

const FeaturedBox = ({ children, title, extraClass, ...otherProps }) => {
    return (
        <div
            className={`featured-box ${extraClass ? extraClass : ""}`}
            {...otherProps}
        >
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <h3 className="title">{title}</h3>
                    </Col>
                    <Col md={12}>{children}</Col>
                </Row>
            </Container>
        </div>
    );
};

export default FeaturedBox;
