import { useEffect } from "react";

function useDocumentTitle(title, prevailOnUnmount = false) {
    // const defaultTitle = useRef(document.title);

    useEffect(() => {
        document.title = `Krown Creative Factory | ${title}`;
    }, [title]);

    useEffect(
        () => () => {
            if (!prevailOnUnmount) {
                document.title = "Krown Creative Factory";
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [title]
    );
}

export default useDocumentTitle;
