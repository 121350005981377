import { createSelector } from "reselect";

export const selectUser = (state) => state.user;

export const selectUserObject = createSelector([selectUser], (user) => user);

export const selectUserDetails = createSelector(
    [selectUser],
    (user) => user.userDetails
);

export const selectPurchases = createSelector(
    [selectUser],
    (user) => user.purchases
);

export const selectBookmarks = createSelector(
    [selectUser],
    (user) => user.bookmarks
);
