import { UserActionTypes } from "./user.types";

const newDate = new Date();

export const INITIAL_STATE = {
    userDetails: {},
    bookmarks: [],
    purchases: [],
    downloadsToday: 0,
    today: `${newDate.getFullYear()}-${newDate.getMonth()}-${newDate.getDate()}`,
};

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UserActionTypes.SET_USER:
            return {
                ...state,
                userDetails: action.payload,
            };
        case UserActionTypes.SET_BOOKMARKS:
            return {
                ...state,
                bookmarks: action.payload,
            };
        case UserActionTypes.SET_PURCHASES:
            return {
                ...state,
                purchases: action.payload,
            };
        case UserActionTypes.SET_DATE:
            return {
                ...state,
                today: `${newDate.getFullYear()}-${newDate.getMonth()}-${newDate.getDate()}`,
            };
        case UserActionTypes.SET_DOWNLOADS:
            return {
                ...state,
                downloadsToday: action.payload,
            };
        case UserActionTypes.CLEAR_USER:
            return {
                ...state,
                userDetails: {},
                purchases: [],
                bookmarks: [],
            };
        default:
            return state;
    }
};

export default userReducer;
