import React from "react";
import { Col, Row } from "react-bootstrap";
import MyContainer from "../mycontainer/mycontainer";
import "./footer.css";
import kcf from "../../assets/images/logo-one.png";
import { Link } from "react-router-dom";
import {
    FaInstagramSquare,
    FaTwitterSquare,
    FaFacebookSquare,
    FaPinterestSquare,
} from "react-icons/fa";
// import CustomInput from "../custom-input/custom-input";
// import CustomButton from "../custom-button/custom-button";

const Footer = () => {
    return (
        <div className="footer">
            <MyContainer>
                <Row>
                    <Col md={3}>
                        <img src={kcf} className="img-fluid" alt="logo" />
                    </Col>
                    <Col md={3}>
                        <h4>Quick links</h4>
                        <Link to="/" className="footer-links">
                            Home
                        </Link>
                        <br />
                        <Link to="/search" className="footer-links">
                            Search
                        </Link>
                        <br />
                        {/* <Link to="/" className="footer-links">
                            Contact
                        </Link>
                        <br /> */}
                        <Link to="/categories" className="footer-links">
                            Categories
                        </Link>
                        <br />
                        <Link to="/collections" className="footer-links">
                            Collections
                        </Link>
                        <br/>
                        <a href="https://medium.com/@pxxlspace22" className="footer-links">
                            Blogs
                        </a>
                    </Col>
                    <Col md={3}>
                        <h4>Legal</h4>
                        <Link className="footer-links" to="/">
                            Terms of use
                        </Link>
                        <br />
                        <Link className="footer-links" to="/">
                            Privacy Policy
                        </Link>
                        <br />
                        <Link className="footer-links" to="/">
                            License Agreement
                        </Link>
                        <br />
                        <Link className="footer-links" to="/">
                            Copyright Information
                        </Link>
                        <br />
                    </Col>
                    <Col md={3}>
                        <h4>Social media</h4>
                        <a
                            href="https://twitter.com/krown_art_work?s=11&t=Kj-A3V05u6gRtO81_PKz-Q"
                            rel="noreferrer"
                            target="blank"
                            className="footer-icons"
                        >
                            <FaTwitterSquare />
                        </a>
                        <a
                            href="https://instagram.com/krown_art_work?igshid=YmMyMTA2M2Y="
                            rel="noreferrer"
                            target="blank"
                            className="footer-icons"
                        >
                            <FaInstagramSquare />
                        </a>
                        <a
                            href="https://www.facebook.com/krownartwork"
                            rel="noreferrer"
                            target="blank"
                            className="footer-icons"
                        >
                            <FaFacebookSquare />
                        </a>
                        <a
                            href="https://www.pinterest.com/krowncreativefactory/"
                            rel="noreferrer"
                            target="blank"
                            className="footer-icons"
                        >
                            <FaPinterestSquare />
                        </a>
                        {/* <div className="mv-30">
                            <h4>Newsletter</h4>
                            <form>
                                <div className="form-group">
                                    <CustomInput
                                        name="email"
                                        type="email"
                                        placeholder="Enter your email"
                                    />
                                </div>
                                <div className="form-group">
                                    <CustomButton
                                        displayText="Subscribe"
                                        fw
                                        formbutton
                                    />
                                </div>
                            </form>
                        </div> */}
                    </Col>
                    <Col md={12}>
                        <div className="copyright">
                            &copy; {new Date().getFullYear()} Krown Creative
                            Factory
                        </div>
                    </Col>
                </Row>
            </MyContainer>
        </div>
    );
};

export default Footer;
