import React from "react";
import "./404.css";
import CustomButton from "../../components/custom-button/custom-button";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../components/document-title/document-title";

const NotFound = () => {
    useDocumentTitle("Page Not Found");
    return (
        <div className="not-found">
            <h1>404</h1>
            <h3>The page you are looking for doesn't seem to exist</h3>
            <Link to="/">
                <CustomButton displayText="Go Back To Homepage" />
            </Link>
        </div>
    );
};

export default NotFound;
