import React from "react";
import { Col } from "react-bootstrap";
import KCFLOADER from "../../assets/images/KCF_Loader.gif";

const LoadingBox = ({ loadingText }) => {
    return (
        <Col
            md={12}
            className="loading-box centered"
            style={{
                paddingTop: "100px",
                paddingBottom: "100px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            {/* <Spinner animation="border" role="status" color="#ED3237"></Spinner> */}
            <img alt="loader" src={KCFLOADER} />
            {loadingText ? (
                <p style={{ textAlign: "center" }}>{loadingText}</p>
            ) : null}
        </Col>
    );
};

export default LoadingBox;
