import apiConnect from "../../api/apiConnect";
import { UserActionTypes } from "./user.types";

export const setUser = (user) => ({
    type: UserActionTypes.SET_USER,
    payload: user,
});

export const setUserPurchases = (purchases) => ({
    type: UserActionTypes.SET_PURCHASES,
    payload: purchases,
});

export const setUserBookmarks = (bookmarks) => ({
    type: UserActionTypes.SET_BOOKMARKS,
    payload: bookmarks,
});

export const clearUser = () => ({
    type: UserActionTypes.CLEAR_USER,
});

export const setDownloads = (downloads) => ({
    type: UserActionTypes.SET_DOWNLOADS,
    payload: downloads,
});

export const setToday = () => ({
    type: UserActionTypes.SET_DATE,
});

export const doBookmark = (item, bookmarks, user_id) => {
    return async (dispatch) => {
        try {
            const itemExists = bookmarks.find((bookmark) => {
                return bookmark.id === item.id;
            });

            if (itemExists) {
                const bodyForm = new FormData();
                bodyForm.append("actionType", "remove");
                bodyForm.append("id", item.id);
                bodyForm.append("user_id", user_id);
                const response = await apiConnect.post("/doBookmark", bodyForm);
                if (response.data.status === "success") {
                    const newBookmarks = bookmarks.filter((bookmark) => {
                        return bookmark.id !== item.id;
                    });
                    dispatch(setUserBookmarks(newBookmarks));
                } else {
                    console.log(response.data.message);
                }
            } else {
                const bodyForm = new FormData();
                bodyForm.append("actionType", "add");
                bodyForm.append("id", item.id);
                bodyForm.append("user_id", user_id);
                const response = await apiConnect.post("/doBookmark", bodyForm);
                if (response.data.status === "success") {
                    const newBookmarks = [...bookmarks, item];
                    dispatch(setUserBookmarks(newBookmarks));
                } else {
                    console.log(response.data.message);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };
};

export const doPurhcase = (item, purchases, user_id) => {
    return async (dispatch) => {
        try {
            const itemExists = purchases.find((purchase) => {
                return purchase.id === item.id;
            });

            if (itemExists) {
                return;
            } else {
                const newPurchases = [...purchases, item];
                dispatch(setUserPurchases(newPurchases));
            }
        } catch (error) {
            console.log(error);
        }
    };
};
