import React, { useEffect, useState } from "react";
import "./header.css";
import Logo from "../../assets/images/logo.png";
import { Navbar, Nav, NavDropdown, Col, Row } from "react-bootstrap";
import { Link, useLocation, useHistory } from "react-router-dom";
import { FiMenu, FiSearch } from "react-icons/fi";
import Sidebar from "../sidebar/sidebar";
import apiConnect from "../../api/apiConnect";
import { selectUserDetails } from "../../redux/user/user.selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { clearUser } from "../../redux/user/user.actions";
import CustomInput from "../custom-input/custom-input";
import CustomSelect from "../custom-select/custom-select";
import CustomButton from "../custom-button/custom-button";

const Header = ({ user, logOut }) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const location = useLocation();
    const { pathname } = location;
    const [categories, setCategories] = useState([]);
    const [type, setType] = useState("");
    const [search, setSearch] = useState("");
    // eslint-disable-next-line no-unused-vars
    const [menu, setMenu] = useState(false);
    // const [categoriesLoading, setCategoriesLoading] = useState(false);
    // const [categoriesError, setCategoriesError] = useState('');
    const history = useHistory();

    const getCategories = async () => {
        // setCategoriesLoading(true);
        // setCategoriesError('');
        try {
            const response = await apiConnect.get("/getCategories");
            if (response.data.status === "success") {
                // setCategoriesLoading(false);
                setCategories(response.data.categories);
            } else {
                console.log("Error in fetching");
                // setCategoriesLoading(false);
                // setCategoriesError(response.data.message);
            }
        } catch (error) {
            console.log(error);
            // setCategoriesError('Something went wrong');
            // setCategoriesLoading(false);
        }
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setSearch(value);
    };

    const handleSelect = (e) => {
        const { value } = e.target;
        setType(value);
    };

    const handleSearch = (e) => {
        e.preventDefault();
        history.push(`/search/${type ? type : "all"}/${search}`);
        setSearch("");
        setType("");
    };

    useEffect(() => {
        setSidebarOpen(false);
        if (categories.length < 1) {
            getCategories();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return (
        <div className="header">
            {sidebarOpen ? (
                <Sidebar
                    user={user}
                    logOut={logOut}
                    closeAction={() => setSidebarOpen(false)}
                />
            ) : null}
            <Navbar collapseOnSelect expand="md" id="navbar" fixed="top">
                <Navbar.Brand as={Link} to="/">
                    <img src={Logo} className="img-fluid" alt="logo" />
                    {/* <span className='brand-text'>Krown Creative Factory</span> */}
                </Navbar.Brand>

                {/* <Navbar.Toggle onClick={() => setSidebarOpen(!sidebarOpen)} aria-controls="responsive-navbar-nav"></Navbar.Toggle> */}

                <button
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                    type="button"
                    aria-label="Toggle navigation"
                    className="navbar-toggler collapsed"
                >
                    <FiMenu />
                </button>

                <Navbar.Collapse id="responsive-navbar-nav">
                    <form
                        className="me-auto hidden-mobile"
                        onSubmit={handleSearch}
                    >
                        <div
                            style={{
                                width: "600px",
                                paddingLeft: "50px",
                            }}
                        >
                            <Row className="gx-0">
                                <Col sm={4}>
                                    <CustomSelect
                                        data={[
                                            {
                                                text: "Search by tags",
                                                value: "tags",
                                            },
                                            {
                                                text: "Search by name",
                                                value: "name",
                                            },
                                        ]}
                                        selectText="Search by"
                                        name="type"
                                        onChange={handleSelect}
                                        value={type}
                                        bungedForm={true}
                                    />
                                </Col>
                                <Col>
                                    <CustomInput
                                        name="search"
                                        placeholder="Search for media items"
                                        bungedForm={true}
                                        value={search}
                                        onChange={handleChange}
                                    />
                                </Col>
                                <Col sm={2}>
                                    <CustomButton
                                        displayText={<FiSearch />}
                                        formbutton={true}
                                        bungedFormButton={true}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </form>
                    <Nav className="ms-auto hidden-mobile">
                        {menu ? (
                            <>
                                <Nav.Link as={Link} to="/">
                                    Home
                                </Nav.Link>
                                <Nav.Link
                                    onClick={() => setSidebarOpen(!sidebarOpen)}
                                >
                                    Categories
                                </Nav.Link>
                                <Nav.Link
                                    className="hidden-desktop"
                                    as={Link}
                                    to="/search"
                                >
                                    Search
                                </Nav.Link>
                                {!user?.user_id ? (
                                    <Nav.Link as={Link} to="/signin">
                                        Sign In
                                    </Nav.Link>
                                ) : (
                                    <NavDropdown
                                        title="Account"
                                        id="collasible-nav-dropdown"
                                    >
                                        <NavDropdown.Item>
                                            {user.username}
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item
                                            as={Link}
                                            to="/bookmarks"
                                        >
                                            My Bookmarks
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item
                                            as={Link}
                                            to="/purchases"
                                        >
                                            My Purchases
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item
                                            onClick={() => logOut()}
                                        >
                                            Sign Out
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                )}
                            </>
                        ) : null}
                        <Nav.Link
                            className="hidden-mobile"
                            onClick={() => setSidebarOpen(!sidebarOpen)}
                        >
                            <FiMenu />
                        </Nav.Link>
                        {/* <Nav.Link as={Link} to="/">Pricing</Nav.Link> */}
                        {/* <Nav.Link as={Link} to="/signup">Sign Up</Nav.Link> */}

                        {/* <Nav.Link as={Link} to="/">j</Nav.Link>
                        <Nav.Link as={Link} to="/">About</Nav.Link>
                        
                        <Nav.Link as={Link} to="/">Say Hi!</Nav.Link> */}
                    </Nav>
                    <Nav className="ms-auto hidden-desktop">
                        <Nav.Link as={Link} to="/">
                            Home
                        </Nav.Link>
                        <Nav.Link onClick={() => setSidebarOpen(!sidebarOpen)}>
                            Categories
                        </Nav.Link>
                        <Nav.Link as={Link} to="/search">
                            Search
                        </Nav.Link>
                        {!user?.user_id ? (
                            <Nav.Link as={Link} to="/signin">
                                Sign In
                            </Nav.Link>
                        ) : (
                            <NavDropdown
                                title="Account"
                                id="collasible-nav-dropdown"
                            >
                                <NavDropdown.Item>
                                    {user.username}
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item as={Link} to="/bookmarks">
                                    My Bookmarks
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item as={Link} to="/purchases">
                                    My Purchases
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={() => logOut()}>
                                    Sign Out
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}
                        {/* <Nav.Link className='hidden-mobile' onClick={() => setMenu(!menu)}><FiMenu /></Nav.Link> */}
                        {/* <Nav.Link as={Link} to="/">Pricing</Nav.Link> */}
                        {/* <Nav.Link as={Link} to="/signup">Sign Up</Nav.Link> */}

                        {/* <Nav.Link as={Link} to="/">j</Nav.Link>
                        <Nav.Link as={Link} to="/">About</Nav.Link>
                        
                        <Nav.Link as={Link} to="/">Say Hi!</Nav.Link> */}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    user: selectUserDetails,
});

const mapDispatchToProps = (dispatch) => ({
    logOut: () => dispatch(clearUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
