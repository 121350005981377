import React from "react";
import { Col, Row } from "react-bootstrap";
import FeaturedBox from "../../components/featured-box/featured-box";
import MyContainer from "../../components/mycontainer/mycontainer";
import MediaItem from "../../components/media-item/media-item";
import "./purchases.css";
import useDocumentTitle from "../../components/document-title/document-title";
import { createStructuredSelector } from "reselect";
import { selectPurchases } from "../../redux/user/user.selector";
import { connect } from "react-redux";

const Purchases = ({ userPurchases }) => {
    useDocumentTitle("User Purchases");

    return (
        <div className="all-items">
            <div className="main-header">Purchased Items</div>
            <FeaturedBox>
                <MyContainer>
                    <Row>
                        {userPurchases.length < 1 ? (
                            <Col md={12} style={{ textAlign: "center" }}>
                                <h4>You don't have any purchased items yet</h4>
                            </Col>
                        ) : null}
                        {userPurchases.map((item, i) => {
                            return (
                                <Col md={3} key={i}>
                                    <MediaItem  item={item} />
                                </Col>
                            );
                        })}
                    </Row>
                </MyContainer>
            </FeaturedBox>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    userPurchases: selectPurchases,
});

export default connect(mapStateToProps)(Purchases);
