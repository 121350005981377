import React, { lazy, Suspense, useEffect } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import Header from "./components/header/header";
import ErrorBoundary from "./components/error-boundary/error-boundary";
import Footer from "./components/footer/footer";
import LoadingBox from "./components/loading-box/loading-box";
import NotFound from "./pages/404/404";
import { createStructuredSelector } from "reselect";
import { selectUserDetails } from "./redux/user/user.selector";
import { connect } from "react-redux";
import Bookmarks from "./pages/bookmarks/bookmarks";
import Purchases from "./pages/purchases/purchases";
import ForgotPassword from "./pages/forgot-password/forgot-password";
import ResetPassword from "./pages/reset-password/reset-password";
import ReactGA from "react-ga4";
//lazy imports

const Homepage = lazy(() => import("./pages/homepage/homepage"));
const Searchpage = lazy(() => import("./pages/searchpage/searchpage"));
const Signup = lazy(() => import("./pages/signup/signup"));
const Signin = lazy(() => import("./pages/signin/signin"));
const AllCategories = lazy(() =>
    import("./pages/all-categories/all-categories")
);
const AllCollections = lazy(() =>
    import("./pages/all-collections/all-collections")
);
const Category = lazy(() => import("./pages/category/category"));
const Collection = lazy(() => import("./pages/collection/collection"));
const AllItems = lazy(() => import("./pages/all-items/all-items"));
const ItemDetails = lazy(() => import("./pages/item-details/item-details"));

ReactGA.initialize("G-2QYRVY2DCC");

const App = ({ user }) => {
    const location = useLocation();
    const runGA = () => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    };

    useEffect(() => {
        runGA();
    }, [location.pathname]);

    return (
        <div className="App">
            <Header />
            <ErrorBoundary>
                <Suspense fallback={<LoadingBox />}>
                    <Switch>
                        <Route exact path="/" component={Homepage} />
                        <Route exact path="/search" component={Searchpage} />
                        <Route
                            exact
                            path="/search/:searchType/:searchName"
                            component={Searchpage}
                        />
                        <Route
                            exact
                            path="/signup"
                            render={() =>
                                user?.user_id ? <Redirect to="/" /> : <Signup />
                            }
                        />
                        <Route
                            exact
                            path="/signin"
                            render={() =>
                                user?.user_id ? <Redirect to="/" /> : <Signin />
                            }
                        />
                        <Route
                            exact
                            path="/forgot-password"
                            render={() =>
                                user?.user_id ? (
                                    <Redirect to="/" />
                                ) : (
                                    <ForgotPassword />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/reset-password/:resetToken"
                            render={() =>
                                user?.user_id ? (
                                    <Redirect to="/" />
                                ) : (
                                    <ResetPassword />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/bookmarks"
                            render={() =>
                                !user?.user_id ? (
                                    <Redirect to="/" />
                                ) : (
                                    <Bookmarks />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/purchases"
                            render={() =>
                                !user?.user_id ? (
                                    <Redirect to="/" />
                                ) : (
                                    <Purchases />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/categories"
                            component={AllCategories}
                        />
                        <Route
                            exact
                            path="/collections"
                            component={AllCollections}
                        />
                        <Route exact path="/all-items" component={AllItems} />
                        <Route
                            exact
                            path="/category/:categoryName"
                            component={Category}
                        />
                        <Route
                            exact
                            path="/collection/:collectionName"
                            component={Collection}
                        />
                        <Route
                            exact
                            path="/item/:itemName"
                            component={ItemDetails}
                        />
                        <Route exact component={NotFound} />
                    </Switch>
                </Suspense>
            </ErrorBoundary>
            <Footer />
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    user: selectUserDetails,
});

export default connect(mapStateToProps)(App);
